<template>
  <v-container>
    <v-text-field
      v-model="name"
      :error-messages="nameErrors"
      :label="this.$t('templates|template_name')"
      required
      solo
      @input="$v.name.$touch()"
      @blur="$v.name.$touch()"
    />
    <v-select
      v-model="language"
      :items="languageList"
      :error-messages="languageErrors"
      :label="$t('common|language')"
      required
      solo
      item-text="title"
      item-value="value"
      @input="$v.language.$touch()"
      @blur="$v.language.$touch()"
    />
    <v-combobox
      v-model="tags"
      chips
      clearable
      label="Template Tags"
      multiple
      solo
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="remove(item)"
        >
          <strong>{{ item }}</strong>&nbsp;
        </v-chip>
      </template>
    </v-combobox>
  </v-container>
</template>

<script>
// import { v4 as uuidv4 } from 'uuid'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import LanguageList from '@/enums/LanguagesList.json'
import cfg from '@/config'

export default {
  mixins: [validationMixin],
  validations: {
    name: { required },
    language: { required }
  },
  data () {
    return {
      languageList: Object.keys(LanguageList).filter((key) => {
        return cfg.usedLanguages.includes(key)
      }).map((key) => {
        return {
          value: key,
          title: LanguageList[key].name
        }
      }),
      name: null,
      language: null,
      tags: []
    }
  },
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push.$t('common|field_required')
      return errors
    },
    languageErrors () {
      const errors = []
      if (!this.$v.language.$dirty) return errors
      !this.$v.language.required && errors.push(this.$t('common|field_required'))
      return errors
    }
  },
  methods: {
    remove (item) {
      this.tags.splice(this.tags.indexOf(item), 1)
      this.tags = [...this.tags]
    },
    validate () {
      this.$v.$touch()
      if (this.$v.$error) return false
      return true
    },
    getData () {
      return {
        name: this.name,
        tags: this.tags,
        language: this.language,
        template: {}
      }
    }
  }
}
</script>
